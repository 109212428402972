import { METHODS, API_BASE_URL, apiCall } from './config';

export const GeoApiV2 = {
  searchVillage: (search) =>
    apiCall(METHODS.GET, API_BASE_URL, `v1/locations/search`, {
      name: search,
      scope: 'Village',
    }),
  searchDistrict: (search) =>
    apiCall(METHODS.GET, API_BASE_URL, `v1/locations/search`, {
      name: search,
      scope: 'District',
    }),
  searchRegency: (search) =>
    apiCall(METHODS.GET, API_BASE_URL, `v1/locations/search`, {
      name: search,
      scope: 'Regency',
    }),
  getLocationByWords: (params) => apiCall(METHODS.POST, API_BASE_URL, `v1/locations/search`, params),
  getLocationByCodes: (params) => apiCall(METHODS.POST, API_BASE_URL, `v1/locations`, params),
  getChildrenByParentId: (parentId) => apiCall(METHODS.POST, API_BASE_URL, `v1/locations`, { parent_id: parentId }),
  getLocationByScope: (scope) => apiCall(METHODS.POST, API_BASE_URL, `v1/locations`, { scope: scope }),
};
