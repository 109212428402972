import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { Form, Modal, notification } from 'antd';
import { MENU } from 'config/menu';

import { AcquisitionReviewApi } from 'api/acquisition-review';
import { AgentApi } from 'api/agent';
import Breadcrumb from 'components/breadcrumb';
import Layout from 'layout/main';

import ApproveConfirmationModal from '../modals/approve';
import RejectConfirmationModal from '../modals/reject';
import SectionDetail from './SectionDetail';
import HistoryLogs from './history-logs';

import ActionButtons from './ActionButtons';
import UndoReview from '../modals/undoReview';
import './detail.scss';

function AcquisitionReviewDetail({ match, form }) {
  const [lastUpdate, setLastUpdate] = useState('');
  const { getFieldDecorator, setFieldsValue } = form;
  const [editMode, setEditMode] = useState(false);
  const [acqData, setAcquisition] = useState();
  const [loading, setLoading] = useState(true);

  // Modals
  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [revertReviewModal, setRevertReviewModal] = useState(false);

  const acquisitionId = match.params.id;

  const updateParent = () => setLastUpdate(new Date());

  const scrollToBTop = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    scrollToBTop();
    await form.validateFields(async (err, values) => {
      if (err) return;

      const payload = {
        ...values,
        location_id: values.location_id,
        location: values.location,
        business_name: values.business_name,
        name: values.name,
        business_type: values.business_type,
        phone: values.phone,
        address: values.address,
        neighbourhood_number: values.neighbourhood_number,
        hamlet_number: values.hamlet_number,
        zip_code: values.zip_code,
        note: values.note,
      };

      Modal.confirm({
        title: 'Update this acquisition data detail?',
        onOk: async () => {
          try {
            setLoading(true);
            setEditMode(false);
            await AgentApi.updateAgent(acqData.id, payload);
            updateParent();
            notification.success({
              message: 'Success',
              description: 'Success update acquisition review',
            });
            setLoading(false);
          } catch (error) {
            console.error(error);
            setLoading(false);
          }
        },
      });
    });
  };

  useEffect(() => {
    if (approveModal) return;
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await AcquisitionReviewApi.getDetail(acquisitionId);
        setAcquisition(res.data);
        setLoading(false);
      } catch (error) {
        console.error('error', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [acquisitionId, lastUpdate]);

  return (
    <Layout title="Acquisition Review">
      <Breadcrumb
        data={[
          {
            link: MENU.ACQUISITION_REVIEW,
            title: 'Acquisition Review',
          },
          {
            link: null,
            title: <b>Acquisition Review Detail</b>,
          },
        ]}
      />
      <div className="app-content__details">
        <Form className="master-field__form" onSubmit={submit}>
          <SectionDetail
            data={acqData}
            loading={loading}
            getFieldDecorator={getFieldDecorator}
            setFieldsValue={setFieldsValue}
            editMode={editMode}
          />
          <ActionButtons
            loading={loading}
            acqData={acqData}
            editMode={editMode}
            onApprove={() => setApproveModal(true)}
            onReject={() => setRejectModal(true)}
            onRevertReview={() => setRevertReviewModal(true)}
            onClickEdit={() => {
              scrollToBTop();
              setEditMode(!editMode);
            }}
          />
        </Form>
        <HistoryLogs lastUpdate={lastUpdate?.toString()} />
      </div>
      <ApproveConfirmationModal payload={acqData} visible={approveModal} close={() => setApproveModal(false)} onSuccess={updateParent} />
      <RejectConfirmationModal payload={acqData} visible={rejectModal} close={() => setRejectModal(false)} onSuccess={updateParent} />
      <UndoReview payload={acqData} visible={revertReviewModal} close={() => setRevertReviewModal(false)} onSuccess={updateParent} />
    </Layout>
  );
}

AcquisitionReviewDetail.propTypes = {
  form: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const WrappedDetail = Form.create({
  name: 'editAcquisition',
})(AcquisitionReviewDetail);

export default withRouter(WrappedDetail);
